<script lang="ts" setup>
const props = withDefaults(defineProps<{ duration?: number }>(), { duration: 0.35 });

const formattedDuration = computed(() => `${props.duration}s`);
</script>

<template>
	<div>
		<slot />
	</div>
</template>

<style scoped lang="scss">
div {
	transition: all v-bind(formattedDuration) ease;
	opacity: 0;
	@media (hover: hover) {
		&:hover {
			opacity: 1;
		}
	}
}
</style>
